import { ROUTE_SEARCH_PARAMS, ROUTES, USER_ROUTES_BASE } from 'common/constants'
import envConfig from 'config'

export const getProfileShareLink = (sharedBy: string, token: string) => {
  const afl = encodeURIComponent(envConfig.dynamicLink?.android.afl || '')
  const apn = encodeURIComponent(envConfig.dynamicLink?.android.apn || '')
  const shareDynamicLink = encodeURIComponent(
    `${envConfig.baseUrl}${ROUTES.PUBLIC_PROFILE}?shared_by=${sharedBy}&token=${token}`
  )
  const shareLink = `${envConfig.dynamicLinkBase}?afl=${afl}&apn=${apn}&link=${shareDynamicLink}`
  return shareLink
}

export const getCardShareLink = (
  sharedBy: string, vacancyId?: string, specialityid?: string, token?: string
) => {
  const cardUrl = vacancyId
    ? `&${ROUTE_SEARCH_PARAMS.VACANCY_ID}=${vacancyId}`
    : `&${ROUTE_SEARCH_PARAMS.SPECIALITY_ID}=${specialityid}`
  const afl = encodeURIComponent(envConfig.dynamicLink?.android.afl || '')
  const apn = encodeURIComponent(envConfig.dynamicLink?.android.apn || '')
  const shareDynamicLink = encodeURIComponent(
    `${envConfig.baseUrl}${ROUTES.PUBLIC_PROFILE}?${ROUTE_SEARCH_PARAMS.SHARED_BY}=${sharedBy}${cardUrl}&token=${token}`
  )
  const shareLink = `${envConfig.dynamicLinkBase}?afl=${afl}&apn=${apn}&link=${shareDynamicLink}`
  return shareLink
}

export const getGroupInviteLink = (groupRef: string) => {
  const inviteLink = `${envConfig.baseUrl}/group/${groupRef}`
  return inviteLink
}

export const getProfileLink = (uid: string, username?: string) => {
  return `${envConfig.baseUrl}/${username || `${USER_ROUTES_BASE.USER}/${uid}`}`
}

export const getInviteLink = (uid: string) => {
  const link = encodeURIComponent(`${envConfig.baseUrl}/?invited_by=${uid}`)
  const afl = encodeURIComponent(envConfig.dynamicLink?.android.afl || '')
  const apn = encodeURIComponent(envConfig.dynamicLink?.android.apn || '')
  const inviteLink = `${envConfig.dynamicLinkBase}?afl=${afl}&apn=${apn}&link=${link}`
  return inviteLink
}
