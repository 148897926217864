import React from 'react'
import styles from './styles.module.sass'

interface IResponsiveImage {
  src?: string
  alt?: string
  size?: number
  icon?: React.ReactNode
}

export const ResponsiveImage: React.FC<IResponsiveImage> = ({
  src,
  alt,
  size = 156,
  icon
}) => {
  return src ? (
    <svg
      className={styles.imgStyles}
      viewBox={`0 0 ${size} ${size}`}
      aria-label={alt || ''}
      width={size}
      height={size}
    >
      <image
        href={src}
        className={styles.imgStyles}
        clipPath="circle(50%)"
        preserveAspectRatio="xMidYMid slice"
        width={size}
        height={size}
      />
    </svg>
  ) : (
    <div className={styles.iconStyles}>
      {icon || ''}
    </div>
  )
}
