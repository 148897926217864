import React, { FC } from 'react'
import { PhotoCropper } from 'features/PhotoCropper_V2'
import styles from './styles.module.sass'

interface IAvatar {
  photo?: string,
  photoURL: string
  uploadPhotoTitle?: string
  width?: undefined | number
  onCropImage: (imageUrl: string) => Promise<void>,
  isGroupImage?: boolean
}

export const Avatar: FC<IAvatar> = ({
  photo, photoURL, uploadPhotoTitle, width, onCropImage, isGroupImage
}) => {
  return (
    <div className={styles.avatarContainer}>
      <PhotoCropper
        uploadPhotoTitle={uploadPhotoTitle}
        width={width}
        initialPhoto={{ photo, photoURL }}
        cropImage={onCropImage}
        isGroupImage={isGroupImage}
      />
    </div>
  )
}
