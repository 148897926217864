import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { PrimaryButton } from 'common/components/Button_V2/PrimaryButton'
import { SecondaryButton } from 'common/components/Button_V2/SecondaryButton'
import { ImageWithTrustMark } from 'common/components/ImageWithTrustMark_V2'
import { ROUTES, SYMBOLS } from 'common/constants'
import { useMobileMediaQuery, useTabletMediaQuery } from 'common/hooks/mediaQuery'
import { LocationIcon } from 'common/icons_V2/LocationIcon'
import { StartTrustIcon } from 'common/icons_V2/StartTrustIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { formatUserName } from 'common/utils/formatUserName'
import { formatLocation } from 'common/utils/profile'
import { actions as contactsActions } from 'features/Contacts/actions'
import { openChatWithUser } from 'features/Conversations/actions'
import { selectUserTrustedById } from 'features/Home/selectors'
import { selectAllLocations } from 'features/Locations/selectors'
import { addContact } from 'features/MyProfile/actions'
import { PhotoModal } from 'features/MyProfile/components/PhotoModal'
import { ProfilePopup } from 'features/MyProfile/components/ProfilePopup'
import { selectMyNetworkContacts, selectTrustedUids } from 'features/MyProfile/selectors'
import type { ProfileType } from 'features/MyProfile/types'
import { PROFILE_ACTIONS_KEYS } from 'features/Translations/constants'
import { selectHomeTranslations, selectOtherProfileTranslations } from 'features/Translations/selectors'
import { useContactActions } from 'providers/ContactActionsProvider'
import { TrustedContacts } from '../TrustedContacts'
import styles from './styles.module.sass'

interface IHeaderOtherProfile {
  profile: ProfileType,
  isHomePage: boolean,
  isLoading: boolean,
  setIsLoading: (isLoading: boolean) => void,
  jobAndCompany: string,
  auth: boolean
}

export const HeaderOtherProfile: FC<IHeaderOtherProfile> = ({
  profile, isHomePage, isLoading = false, setIsLoading, jobAndCompany, auth
}) => {
  const locations = useSelector(selectAllLocations())
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    photoURL, photo, username, uid, location
  } = profile
  const homeTranslations = useSelector(selectHomeTranslations)
  const [isOpenPhotoModal, setIsOpenPhotoModal] = useState(false)
  const [isLoadingTrusted, setIsLoadingTrusted] = useState(false)
  const isMobile = useMobileMediaQuery()
  const isTablet = useTabletMediaQuery()
  const { trust } = useContactActions()
  const otherProfileTranslations = useSelector(selectOtherProfileTranslations)
  const contacts = useSelector(selectMyNetworkContacts)
  const isExistingContact = contacts.some((contact) => contact.uid === uid)
  const trustedUids = useSelector(selectTrustedUids)
  const isTrustedContact = trustedUids.includes(uid)
  const trustedContactsOtherProfile = useSelector(selectUserTrustedById(profile?.uid || ''))

  const onClick = async () => {
    try {
      setIsLoading(true)
      await dispatch(addContact(uid, isExistingContact))
    } finally {
      setIsLoading(false)
    }
  }

  const renderButtonsForNewContact = () => (
    <>
      <PrimaryButton
        title={otherProfileTranslations[PROFILE_ACTIONS_KEYS.ADD_CONTACT]}
        className={cn(styles.button, styles.introduceButton)}
        onClick={onClick}
      />
      <SecondaryButton
        title={
          isHomePage
            ? homeTranslations.startChatButton
            : otherProfileTranslations.chatButton
        }
        className={cn(styles.ghostButtonStyle, styles.button, styles.chatButton)}
        isLoading={isLoading}
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true)
          dispatch(openChatWithUser([profile], () => {
            setIsLoading(false)
            history.push(ROUTES.MESSAGES)
          }))
        }}
      />
      <div className={styles.actions}>
        <ProfilePopup uid={uid} profile={profile} />
      </div>
    </>
  )

  const renderButtonsForExistingContact = () => (
    <>
      <PrimaryButton
        title={
          isHomePage
            ? homeTranslations.startChatButton
            : otherProfileTranslations.chatButton
        }
        className={cn(styles.button, styles.ghostButtonStyle)}
        isLoading={isLoading}
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true)
          dispatch(openChatWithUser([profile], () => {
            setIsLoading(false)
            history.push(ROUTES.MESSAGES)
          }))
        }}
      />
      <SecondaryButton
        title={otherProfileTranslations.introduceButton}
        className={cn(styles.button)}
        onClick={() => {
          if (!isLoading) dispatch(contactsActions.setUserToIntroduce(profile))
        }}
      />
      {!isTrustedContact && (
        <SecondaryButton
          title={<StartTrustIcon />}
          disabled={isLoadingTrusted}
          className={styles.trustActionButton}
          onClick={async () => {
            if (!isLoadingTrusted) {
              setIsLoadingTrusted(true)
              await trust(uid)
              setIsLoadingTrusted(false)
            }
          }}
        />
      )}
      <ProfilePopup uid={uid} profile={profile} />
    </>
  )

  return (
    <>
      <div className={styles.headerOtherProfileContainer}>
        {isMobile ? (
          <div className={cn(styles.contactInfo, styles.headerOtherProfileForMobile)}>
            <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="p">
              {formatUserName(profile)}
            </Typography>
            {username && (
              <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p">
                {`${SYMBOLS.AT_SYMBOL}${username}`}
              </Typography>
            )}
            <ImageWithTrustMark
              onClick={() => setIsOpenPhotoModal(!!photo || !!(photoURL || ''))}
              photo={photo}
              photoURL={photoURL || ''}
              width={76}
              uid={uid}
              className={(!photo && !photoURL) ? styles.emptyPhotoContainer : ''}
            />
            <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="p" className={styles.jobAndCompany}>
              {jobAndCompany}
            </Typography>
            <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="p" className={styles.iconStyles}>
              {location && (
                <>
                  <LocationIcon />
                  {formatLocation(location, locations)}
                </>
              )}
            </Typography>
            {trustedContactsOtherProfile && (
              <TrustedContacts
                profile={profile}
                isOtherProfile
                auth={auth}
              />
            )}
          </div>
        ) : (
          <div className={styles.headerOtherProfile}>
            <ImageWithTrustMark
              onClick={() => setIsOpenPhotoModal(!!photo || !!(photoURL || ''))}
              photo={photo}
              photoURL={photoURL || ''}
              uid={uid}
              width={156}
              trustInfoStyles={styles.trustInfoStyles}
            />
            <div className={styles.contactInfo}>
              <div className={styles.infoStyles}>
                <div className={styles.userInfo}>
                  <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="p">
                    {formatUserName(profile)}
                  </Typography>
                  {username && (
                    <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p">
                      {`${SYMBOLS.AT_SYMBOL}${username}`}
                    </Typography>
                  )}
                  <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="p" className={styles.jobAndCompany}>
                    {jobAndCompany}
                  </Typography>
                  <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="p" className={styles.iconStyles}>
                    {location && (
                      <>
                        <LocationIcon />
                        {formatLocation(location, locations)}
                      </>
                    )}
                  </Typography>
                  {trustedContactsOtherProfile && !isTablet && (
                    <TrustedContacts
                      profile={profile}
                      isOtherProfile
                      auth={auth}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {trustedContactsOtherProfile && isTablet && (
          <div className={styles.trustedContactsMyProfile}>
            <TrustedContacts
              profile={profile}
              isOtherProfile
              auth={auth}
            />
          </div>
        )}
        {auth && (
          <div className={styles.buttonContainer}>
            {!isExistingContact && renderButtonsForNewContact()}
            {isExistingContact && renderButtonsForExistingContact()}
          </div>
        )}
      </div>
      <PhotoModal
        isOpen={isOpenPhotoModal}
        photoURL={photoURL}
        onClose={() => setIsOpenPhotoModal(false)}
      />
    </>
  )
}
