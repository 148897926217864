import { getAllInfoByISO, getAllISOByCurrencyOrSymbol } from 'iso-country-currency'

const customCurrencySymbols: Record<string, string> = {
  AMD: '\u058F'
}

export const getSymbolByCurrency = (currency: string) => {
  try {
    const [country] = getAllISOByCurrencyOrSymbol('currency', currency)
    const { symbol } = getAllInfoByISO(country)
    return customCurrencySymbols[currency] || symbol
  } catch {
    return currency
  }
}
