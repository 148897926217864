import { useSelector } from 'react-redux'
import { CopyButton } from 'common/components/CopyButton'
import { useMobileMediaQuery } from 'common/hooks/mediaQuery'
import { EmptyMyNetwork } from 'common/icons_V2/EmptyMyNetwork'
import { MobilyEmptyMyNetwork } from 'common/icons_V2/MobilyEmptyMyNetwork'
import { getInviteLink } from 'common/utils/dynamicLinks'
import { EmptyInviteContainer } from 'features/Contacts/Network/MyNetwork/components/EmptyInviteContainer'
import { getMyUid } from 'features/MyProfile/selectors'
import { selectInviteViaLinkTranslations, selectMyNetworkTranslations } from 'features/Translations/selectors'

export const WelcomeContacts = () => {
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const isMobile = useMobileMediaQuery()
  const myUid = useSelector(getMyUid)
  const inviteLink = getInviteLink(myUid)
  const inviteViaLinkTranslations = useSelector(selectInviteViaLinkTranslations)
  return (
    <EmptyInviteContainer
      title={myNetworkTranslations.contactsEmptyTitle}
      description={myNetworkTranslations.contactsEmptyDescription}
      inviteButtonTitle={myNetworkTranslations.networkInviteButtonTitle}
      icon={isMobile ? <MobilyEmptyMyNetwork /> : <EmptyMyNetwork />}
      imgAlt={myNetworkTranslations.contactsEmptyTitle}
      shareModalTitle={myNetworkTranslations.networkShareModalTitle}
      copyButton={(
        <CopyButton
          shareLink={inviteLink}
          title={inviteViaLinkTranslations.copyLinkShort}
          copiedTitle={inviteViaLinkTranslations.copyToClipBoardShort}
          showLink
        />
      )}
    />
  )
}
