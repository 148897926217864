import { LANGUAGE_TYPE } from 'common/enums'

export const ROLES = { EMPLOYEE: 'employee', EMPLOYER: 'employer' }

export const VOIP_TOKEN = '12428345723486-34639456-4563-4956'
export const BUNDLE = 'opentek.us.UpLiftDev'

export const LOCAL_STORAGE_VALUES = {
  SCHEDULED_MEETINGS: 'scheduledMeetingsToNotify',
  SEPARATOR: '_',
  LANGUAGE: 'language'
}

export const BROWSER_PERMISSIONS = {
  GRANTED: 'granted'
}

export const LISTENERS = {
  BEFOREUNLOAD: 'beforeunload'
}

export enum ConversationListeners {
  REACHABILITY_ONLINE = 'reachabilityOnline',
  UPDATED = 'updated',
  MESSAGE_UPDATED = 'messageUpdated',
  LAST_READ_TIMESTAMP = 'lastReadTimestamp',
  LAST_READ_MESSAGE_INDEX = 'lastReadMessageIndex',
  TYPING_STARTED = 'typingStarted',
  TYPING_ENDED = 'typingEnded',
  MESSAGE_ADDED = 'messageAdded',
  PARTICIPANT_UPDATED = 'participantUpdated',
  TOKEN_ABOUT_TO_EXPIRE = 'tokenAboutToExpire',
  MESSAGE_REMOVED = 'messageRemoved',
  CONVERSATION_ADDED = 'conversationAdded',
  CONNECTION_ERROR = 'connectionError'
}

export const JOB_SEEKER_OR_OFFER = {
  EMPLOYER: 'Professional Hobbies',
  EMPLOYEE: 'Dream jobs'
}

export const ROUTE_SEGMENTS = {
  ABOUT: '/about',
  VACANCIES: '/vacancies',
  SPECIALTIES: '/specialties'
}

// TODO: reuse in all usages
export const ROUTE_PARAMS = {
  VACANCY_ID: ':vacancyId',
  SPECIALITY_ID: ':specialityId',
  UID: ':uid',
  USERNAME: ':username',
  USERS: ':users',
  GROUPREF: ':groupRef'
}

export const ROUTE_SEARCH_PARAMS = {
  SHARED_BY: 'shared_by',
  VACANCY_ID: 'vacancy_id',
  SPECIALITY_ID: 'speciality_id',
  TOKEN: 'token',
  LANGUAGE: 'lang'
}

export const QUERY_PARAMS = {
  REDIRECT: 'redirect'
}

export const USER_ROUTES_BASE = {
  PROFILE: 'profile',
  USER: 'user',
  CONTACTS: 'contacts'
}

export const ROUTES = {
  LANDING: '/',
  ADMIN: '/admin',
  AUTH: '/auth',
  CALENDAR: 'calendar',
  CONTACTS: `/${USER_ROUTES_BASE.CONTACTS}`,
  CONTACT_VACANCIES: `/${USER_ROUTES_BASE.CONTACTS}/${ROUTE_PARAMS.UID}/vacancies`,
  CONVERSATIONS: '/conversations',
  CHAT: '/chat',
  MESSAGES: '/messages',
  LISTINGS: `/listings/${ROUTE_PARAMS.UID}`,
  SIGN_UP: '/sign_up',
  EXPLORE: '/explore',
  EXPLORE_VACANCY: `/user/${ROUTE_PARAMS.UID}${ROUTE_SEGMENTS.VACANCIES}/${ROUTE_PARAMS.VACANCY_ID}`,
  EXPLORE_SPECIALITY: `/user/${ROUTE_PARAMS.UID}${ROUTE_SEGMENTS.SPECIALTIES}/${ROUTE_PARAMS.SPECIALITY_ID}`,
  PUBLIC_PROFILE: '/publicProfile',
  PUBLIC_VACANCY: '/publicVacancy',
  PUBLIC_SPECIALITY: '/publicSpeciality',
  SURF: '/surf',
  PROFILE_BASE: `/${USER_ROUTES_BASE.PROFILE}`,
  PROFILE: `/${USER_ROUTES_BASE.PROFILE}/${ROUTE_PARAMS.UID}`,
  PROFILE_ABOUT: `/${USER_ROUTES_BASE.PROFILE}/${ROUTE_PARAMS.UID}${ROUTE_SEGMENTS.ABOUT}`,
  PROFILE_VACANCIES: `/${USER_ROUTES_BASE.PROFILE}/${ROUTE_PARAMS.UID}${ROUTE_SEGMENTS.VACANCIES}`,
  PROFILE_VACANCY: `/${USER_ROUTES_BASE.PROFILE}/${ROUTE_PARAMS.UID}${ROUTE_SEGMENTS.VACANCIES}/${ROUTE_PARAMS.VACANCY_ID}`,
  PROFILE_SPECIALTIES: `/${USER_ROUTES_BASE.PROFILE}/${ROUTE_PARAMS.UID}${ROUTE_SEGMENTS.SPECIALTIES}`,
  PROFILE_SPECIALITY: `/${USER_ROUTES_BASE.PROFILE}/${ROUTE_PARAMS.UID}${ROUTE_SEGMENTS.SPECIALTIES}/${ROUTE_PARAMS.SPECIALITY_ID}`,
  USER_ABOUT: `/${USER_ROUTES_BASE.USER}/${ROUTE_PARAMS.UID}${ROUTE_SEGMENTS.ABOUT}`,
  USER: `/${USER_ROUTES_BASE.USER}/${ROUTE_PARAMS.UID}`,
  USERNAME: `/${ROUTE_PARAMS.USERNAME}`,
  USER_ROUT: `/${USER_ROUTES_BASE.USER}`,
  GROUP: `/group/${ROUTE_PARAMS.GROUPREF}`,
  SUPPORT: '/support',
  CLOSED_VACANCIES: '/closedVacancies',
  PRIVACY_POLICY: '/privacy',
  SETTINGS: '/settings',
  TERMS_OF_SERVICE: '/terms',
  NOT_FOUND: '/404'
}

export const MESSAGE_LISTENER_BLACKLIST_ROUTES = [ROUTES.MESSAGES]

export const CHAT_MEDIA_KEY = 'media'

export const CHAT_CONSTATNS = {
  MAX_UNREAD_COUNT: 100,
  MAX_UNREAD_DISPLAY: '99+'
}

export const CALL_MESSAGE_TYPES = {
  MISSED_CALL: 'Missed call',
  OUTGOING_CALL: 'Outgoing call',
  INCOMING_CALL: 'Incoming call'
}

export const REGEX = {
  WEBSITE_LINK: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  STARTS_WITH_HTTP: /https?:\/\//g,
  DIGITS: /^[1-9][0-9]*$/,
  DASH: /[ \-/]/g,
  EMAIL: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
  LAST_SPACE: /\s+(?=\S*$)/,
  USER_NAME: /^(?!.*__)(?!_)[a-z0-9_]+(?<!_)$/
}

export const SYMBOLS = {
  COMMA: ',',
  HASHTAG: '#',
  SPACE: ' ',
  DASH: '-',
  SLASH: '/',
  DOTS: '...',
  BE_LOCATION_SEPARATOR: ', ',
  FE_LOCATION_SEPARATOR: ', ',
  AT_SYMBOL: '@'
}

export const LENGTH_LIMITS = {
  MAX: {
    LOCATION: 75,
    LOCATION_COUNT: 5,
    JOB_TITLE: 85,
    COMPANY: 35,
    FIRST_NAME: 30,
    LAST_NAME: 30,
    USER_NAME: 30,
    JOB_DESCRIPTION: 2000,
    ABOUT_US: 300,
    ABOUT_JOB: 300,
    SPECIALTY: 50,
    INTRO_MESSAGE: 500,
    DREAM_JOB: 50,
    TAG_LENGTH: 24,
    TAGS_COUNT: 10,
    LINK_NAME: 30,
    LINK_URL: 250,
    LINKS_COUNT: 20,
    SUPPORT_NAME: 70,
    SUPPORT_EMAIL: 254,
    SUPPORT_MESSAGE: 2000,
    DIGITS_NUMBER: 9,
    COUNT_TAGS_FOR_VIEW: 4,
    GROUP_NAME: 100
  },
  MIN: {
    USER_NAME: 5,
    ABOUT_JOB: 3
  },
  LIMIT_INDICATOR: 50
}

export const FAKE_PHONE_NUMBERS = [
  ...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => `+1650555${n}${n}${n}${n}`),
  ...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => `+1651555${n}${n}${n}${n}`)
]

export const MEDIA_BREAKPOINTS = {
  lg: 1023,
  xs: 640
}

export const PAY_PERIODS: {[key: string]: string} = {
  PER_HOUR: 'perHour',
  PER_DAY: 'perDay',
  PER_MONTH: 'perMonth',
  PER_YEAR: 'perYear',
  PER_WEEK: 'perWeek',
  FIXED_PRICE: 'fixedPrice'
}

export const TYPE_OF_JOB: {[key: string]: string} = {
  FULL_TIME: 'full-time',
  PART_TIME: 'part-time',
  CONTRACTOR: 'contractor',
  SMALL_JOBS: 'small jobs (gardener, painter, etc)',
  INTERNSHIP: 'internship',
  VOLUNTEER: 'volunteer',
  ADVISOR: 'advisor',
  BOARD_OF_DIRECTORS: 'board of directors',
  PROJECT_BASED: 'project-based/crowdsourcing',
  STARTUP_BUSINESS_PARTNER: 'startup business partner'
}
export const AUDIENCES: {[key: string]: string} = {
  ONLY_TRUSTS: 'Only trusts',
  MY_NETWORK: 'My network',
  STRANGERS: 'Strangers',
  ONLY_ME: 'Only me'
}

export const JOB_TYPE_KEYS = {
  REMOTE: 'remote',
  ON_SITE: 'onSite',
  HYBRID: 'hybrid'
}

export const STATUS_VACANCIES: {[key: string]: string} = {
  CLOSED: 'closed',
  OPEN: 'open'
}

export const CURRENCIES = ['USD', 'EUR', 'AMD']

export const MESSAGE_AUTHOR = {
  SYSTEM: 'system'
}

export const SYSTEM_MESSAGE_TYPE = {
  CLOSE_VACANCY: 'close_vacancy',
  FAVOR_VACANCY: 'favor_vacancy',
  OFFER_VACANCY: 'offer_vacancy',
  MODIFIED_RESUME: 'modified_resume',
  MODIFIED_VACANCY: 'modified_vacancy',
  CLOSED_VACANCY: 'closed_vacancy',
  CLOSED_RESUME: 'closed_resume'
}

export const RECAPTCHA_ELEMENT_ID = 'recaptcha-element'
export const RECAPTCHA_CONTAINER_ID = 'recaptcha-container'

export const MEDIA_RECORDER_STATES = {
  INACTIVE: 'inactive'
}

export const LOCALISE_SEPARATORS = {
  DIGIT: '%d',
  ANY: '%1$s',
  USER: '%user',
  JOB: '%job'
}

export const VACANCY_MODAL_WIDTH = 840
export const ALERT_DISPLAY_DURATION_MS = 2000

export const EXPLORE_CARD_DATE_FORMAT = 'MMM DD, YYYY'
export const EXPLORE_CARD_DETAILS_DATE_FORMAT = 'MMM D HH:mm'
export const EXPLORE_CARD_TIME_FORMAT_BE = 'YYYY-MM-DDTHH:mm:ss.SSS'

export const API_STATUS_CODES = {
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  INTERNAL_SERVER_ERROR: 500
}

export const CHAT_NAME_UID_SEPARATOR = '-'

// Country codes with regions
export const CC_WITH_REGION = ['US']

export const NOTIFICATION_HIDE_DELAY_MS = 700

export const TOAST_DURATION = 5000

export const NAVBAR_ORIGIN = 'navbar'

export const MAX_TRUST_LEVEL = 2

export const URL_REQUEST = 1

export const DEFAULT_LANGUAGE = LANGUAGE_TYPE.EN
