import { FC, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import {
  Link, Redirect, useHistory, useLocation
} from 'react-router-dom'
import cn from 'classnames'
import { ROUTES } from 'common/constants'
import { useMobileMediaQuery } from 'common/hooks/mediaQuery'
import { Logo } from 'common/icons/Logo'
import { getIsFullScreen } from 'common/selectors'
import { checkRequestPublicProfile } from 'common/utils/checkRequestPublicProfile'
import { isExistingRoute } from 'common/utils/url'
import { getAuth } from 'features/Auth/selectors'
import { Footer } from 'features/Footer'
import { PublicHeader } from 'features/Header/PublicHeader'
import styles from './styles_V2.module.sass'

export const PublicLayout: FC<
  PropsWithChildren<{ disableLoginOnMobile?: boolean }>
> = ({ children, disableLoginOnMobile = false }) => {
  const isFullScreen = useSelector(getIsFullScreen)
  const auth = useSelector(getAuth)
  const history = useHistory()
  const paramsPublicProfile = checkRequestPublicProfile(history.location.search)
  const location = useLocation()
  const isMobile = useMobileMediaQuery()

  const style = isFullScreen
    ? {
      maxWidth: '100%',
      overflow: 'auto',
      height: 'calc(100vh - 100px)',
      border: '1px solid #D7DFED'
    }
    : {}

  const whitelistedPublicRoutes = [
    ROUTES.SUPPORT,
    ROUTES.PRIVACY_POLICY,
    ROUTES.TERMS_OF_SERVICE,
    ROUTES.NOT_FOUND
  ]

  const hideLoginRoutes = [
    ROUTES.PRIVACY_POLICY,
    ROUTES.TERMS_OF_SERVICE,
    ROUTES.NOT_FOUND
  ]

  if (
    auth === false &&
    !paramsPublicProfile &&
    !whitelistedPublicRoutes.includes(location.pathname)
  ) {
    const redirectUrl = isExistingRoute(location.pathname)
      ? `${ROUTES.AUTH}?redirect=${encodeURIComponent(location.pathname)}`
      : ROUTES.AUTH

    return <Redirect to={redirectUrl} />
  }
  const showPublicHeader = location.pathname !== ROUTES.NOT_FOUND
  const hideLogin = (isMobile && disableLoginOnMobile)
    || (!isMobile && hideLoginRoutes.includes(location.pathname))

  return (
    <div className={styles.wrapper}>
      {hideLogin && showPublicHeader && (
        <div className={styles.centeredLogo}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
      )}
      {!hideLogin && showPublicHeader && (
        <PublicHeader />
      )}
      <div className={cn(styles.wrapper, showPublicHeader && styles.content)} style={{ height: showPublicHeader ? 'calc(100vh - 86px)' : '' }}>
        <div className={styles.container} style={style}>
          {children}
        </div>
        <Footer />
      </div>
    </div>
  )
}
