import { FC } from 'react'
import cn from 'classnames'
import { GhostButton } from 'common/components/Button_V2/GhostButton'
import { PrimaryButton } from 'common/components/Button_V2/PrimaryButton'
import { SecondaryButton } from 'common/components/Button_V2/SecondaryButton'
import { TransparentButton } from 'common/components/Button_V2/TransparentButton'
import { Typography, TypographyVariants } from 'common/typography'
import styles from './styles.module.sass'

export enum ButtonType {
  DEFAULT,
  DANGER,
  GHOST,
  SECONDARY,
  TRANSPARENT
}

export interface IModalFooter {
  primaryButtonType?: ButtonType
  secondaryButtonType?: ButtonType
  primaryButtonText?: string
  primaryButtonOnClick?: () => void
  isPrimaryButtonDisabled?: boolean
  isPrimaryButtonLoading?: boolean
  footerStyles?: string
  buttonStyles?: string
  secondaryButtonText?: string
  secondaryButtonOnClick?: () => void
  isSecondaryButtonDisabled?: boolean
  isSecondaryButtonLoading?: boolean
}

const secondaryButtonTypeMap = {
  [ButtonType.DEFAULT]: SecondaryButton,
  [ButtonType.DANGER]: SecondaryButton,
  [ButtonType.SECONDARY]: SecondaryButton,
  [ButtonType.GHOST]: GhostButton,
  [ButtonType.TRANSPARENT]: TransparentButton
}

const primaryButtonMap = {
  [ButtonType.DEFAULT]: PrimaryButton,
  [ButtonType.DANGER]: PrimaryButton,
  [ButtonType.SECONDARY]: SecondaryButton,
  [ButtonType.GHOST]: GhostButton,
  [ButtonType.TRANSPARENT]: TransparentButton
}

export const ModalFooter: FC<IModalFooter> = ({
  primaryButtonType = ButtonType.DEFAULT,
  secondaryButtonType = ButtonType.DEFAULT,
  footerStyles,
  primaryButtonText,
  buttonStyles,
  primaryButtonOnClick,
  isPrimaryButtonDisabled,
  isPrimaryButtonLoading,
  secondaryButtonText,
  secondaryButtonOnClick,
  isSecondaryButtonDisabled,
  isSecondaryButtonLoading
}) => {
  if (!primaryButtonText) return <></>

  const SecondaryButtonComponent = secondaryButtonTypeMap[secondaryButtonType]
  const PrimaryButtonComponent = primaryButtonMap[primaryButtonType]

  return (
    <div className={cn(footerStyles, styles.footer)}>
      {secondaryButtonText && (
        <SecondaryButtonComponent
          title={(
            <Typography variant={TypographyVariants.Body_1_Medium} tag="p">
              {secondaryButtonText}
            </Typography>
          )}
          onClick={secondaryButtonOnClick}
          className={cn(
            styles.ghostButton,
            secondaryButtonType === ButtonType.DANGER && styles.dangerSecondaryButton
          )}
          disabled={isSecondaryButtonDisabled || isSecondaryButtonLoading}
          isLoading={isSecondaryButtonLoading}
        />
      )}
      <PrimaryButtonComponent
        type="submit"
        title={(
          <Typography variant={TypographyVariants.Body_1_Medium} tag="p">
            {primaryButtonText}
          </Typography>
        )}
        className={cn(
          buttonStyles || styles.primaryButton,
          primaryButtonType === ButtonType.DANGER && styles.dangerPrimaryButton
        )}
        onClick={primaryButtonOnClick}
        disabled={isPrimaryButtonDisabled || isPrimaryButtonLoading}
        isLoading={isPrimaryButtonLoading}
      />
    </div>
  )
}
