import type { ActionTypes, Countries, CountryNames } from 'features/Locations/types'

const initialState = {
  countries: {} as Countries,
  countryNames: {} as CountryNames,
  cities: [] as string[][],
  regions: {} as { [key: string]: { [key: string]: string } },
  areas: [] as {
    cities: string[]
    country: string
    name: string
  }[]
}

const createCountryNames = (data: Countries): CountryNames => {
  const reverseLookup: CountryNames = {}
  Object.entries(data).forEach(([code, name]) => {
    reverseLookup[name] = code
  })
  return reverseLookup
}

export const LocationReducer = (state = initialState, action: ActionTypes): typeof initialState => {
  switch (action.type) {
    case 'LOCATIONS__SET_ALL_LOCATIONS': {
      return {
        ...state,
        ...action?.locations,
        countryNames: createCountryNames(action?.locations?.countries || {})
      }
    }
    default: return state
  }
}
