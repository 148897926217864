import React, { FC, ReactChild } from 'react'
import cn from 'classnames'
import { PreloaderIcon } from 'common/icons/PreloaderIcon'
import { Typography, TypographyVariants } from 'common/typography'
import styles from './styles.module.sass'

interface IButton {
  type?: 'submit' | 'button';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading?: boolean;
  title?: ReactChild;
  icon?: ReactChild;
  className?: string;
  disabled?: boolean;
  tabIndex?: number;
}

export const Button: FC<IButton> = ({
  type = 'button',
  onClick,
  isLoading,
  title,
  icon,
  className,
  disabled,
  tabIndex
}) => {
  const buttonTitle = (
    <div className={cn(icon && styles.buttonWithIcon)}>
      {icon}
      {title && (
        <Typography variant={TypographyVariants.Body_1_Medium} tag="span">
          {title}
        </Typography>
      )}
    </div>
  )

  return (
    <button
      type={type}
      onClick={(e) => {
        if (!isLoading && !disabled) onClick?.(e)
      }}
      className={cn(styles.button, className)}
      disabled={disabled}
      tabIndex={tabIndex}
    >
      <div className={styles.buttonWithIcon}>
        {isLoading && <PreloaderIcon stroke="#96baf6" />}
        {buttonTitle}
      </div>
    </button>
  )
}
