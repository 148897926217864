import { FC, ReactChild, useEffect } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { Button } from 'common/components/Button_V2'
import { CheckedIcon } from 'common/icons/CheckedIcon'
import { StateType } from 'features/FilterItems/types'
import { LocationState } from 'features/Listing/types'
import styles from './styles.module.sass'

interface IFilterItems {
  items: {
    id: number
    name: ReactChild
  }[]
  setActiveFilters: (filters: number[]) => void
  activeFilters: number[]
  defaultFilter?: number
  filtersContent?: string
  allowMultiple?: boolean
  refreshOnSelect?: boolean
  state?: StateType
}

export const FilterItems: FC<IFilterItems> = ({
  items,
  setActiveFilters,
  activeFilters,
  defaultFilter,
  filtersContent,
  state = StateType.DEFAULT,
  allowMultiple = true,
  refreshOnSelect = false
}) => {
  const history = useHistory()
  const location = useLocation<LocationState>()

  useEffect(() => {
    if (activeFilters.length === 0 && defaultFilter) {
      setActiveFilters([defaultFilter])
    }
  }, [activeFilters, defaultFilter, setActiveFilters])

  const handleClick = (item: { id: number; name: ReactChild }) => {
    if (activeFilters.includes(item.id)) {
      setActiveFilters(activeFilters.filter((id) => id !== item.id))
    } else if (allowMultiple) {
      setActiveFilters([...activeFilters, item.id])
    } else {
      setActiveFilters([item.id])
    }
    if (refreshOnSelect) {
      history.push({
        state: location.state,
        search: ''
      })
    }
  }

  const getCurrentState = (id: number) => {
    return activeFilters?.includes(id) ? StateType.ACTIVE : state
  }

  const stateStyleMap: { [key in StateType]?: string } = {
    [StateType.LIGHT]: styles.lightFilter,
    [StateType.DEFAULT]: styles.defaultFilter,
    [StateType.ACTIVE]: styles.active
  }

  return (
    <ScrollContainer
      className={cn(styles.filters, filtersContent)}
      vertical={false}
      horizontal
      hideScrollbars
    >
      {items.map((item) => (
        <Button
          title={item.name}
          icon={activeFilters.includes(item.id) ? <CheckedIcon stroke="#306FD1" /> : undefined}
          onClick={() => handleClick(item)}
          key={item.id}
          className={cn(
            styles.filtersButton,
            stateStyleMap[getCurrentState(item.id)]
          )}
        />
      ))}
    </ScrollContainer>
  )
}
