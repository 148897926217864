export enum FIELDS {
  JOB_NAME = 'jobName',
  LOCATION_TYPE = 'locationType',
  LOCATIONS = 'locations',
  JOB_TYPE = 'type',
  ABOUT_JOB = 'description',
  TAGS = 'tags',
  CURRENCY = 'currency',
  SALARY_MAX = 'salaryMax',
  SALARY_MIN = 'salaryMin',
  PAYMENT = 'payment',
  RATE = 'payPeriod',
  AUDIENCE = 'audience',
  URL = 'links'
}

export enum INPUT_TYPES {
  INPUT = 'input',
  EXPENDABLE_INPUT = 'expandableInput',
  TEXTAREA = 'textarea',
}

export enum LANGUAGE_TYPE {
  EN = 'en',
  HY = 'hy'
}

export enum TLD {
  COM = 'com',
  AM = 'am'
}
