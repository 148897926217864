import { LANGUAGE_TYPE, TLD } from 'common/enums'

export const getLanguageFromDomain = (): LANGUAGE_TYPE => {
  const domainParts = window.location.hostname.split('.')
  const tld = domainParts[domainParts.length - 1] as TLD
  switch (tld) {
    case TLD.COM:
      return LANGUAGE_TYPE.EN
    case TLD.AM:
      return LANGUAGE_TYPE.HY
    default:
      return LANGUAGE_TYPE.EN
  }
}
