import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ROUTE_PARAMS, ROUTES } from 'common/constants'
import { Typography, TypographyVariants } from 'common/typography'
import { GroupParticipantInfo } from 'features/Contacts/Network/GroupPage/components/GroupParticipantInfo'
import { GroupType } from 'features/Contacts/Network/GroupPage/types'
import { Modal } from 'features/Modal_V2'
import { joinToGroup } from 'features/MyProfile/actions'
import { getMyUid } from 'features/MyProfile/selectors'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IGroupJoinModal {
  isOpen: boolean
  onClose: (skipRedirect: boolean) => void
  group: GroupType
}

export const GroupJoinModal = ({ isOpen, onClose, group }: IGroupJoinModal) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const myUid = useSelector(getMyUid)
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)

  const [isJoining, setIsJoining] = useState(false)

  const handleJoinGroup = async () => {
    setIsJoining(true)
    await dispatch(joinToGroup(group.url, myUid, group))
    setIsJoining(false)
    onClose(true)
    history.push({ pathname: ROUTES.GROUP.replace(ROUTE_PARAMS.GROUPREF, group.url) })
  }

  return (
    <Modal
      onClose={() => { onClose(false) }}
      isOpen={isOpen}
      width={464}
      primaryButtonText={myNetworkTranslations.joinGroupButton}
      primaryButtonOnClick={handleJoinGroup}
      footerStyles={styles.footerStyles}
      isPrimaryButtonLoading={isJoining}
    >
      <div className={styles.joinModalContainer}>
        <GroupParticipantInfo photoURL={group.photoURL} photo={group.photo} groupName={group.name} />
        <div className={styles.joinModalDescription}>
          <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="p" className={styles.membersCount}>
            {group.members.length}
          </Typography>
          <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p">
            {myNetworkTranslations.joinGroupMembersText}
          </Typography>
        </div>
      </div>
    </Modal>
  )
}
