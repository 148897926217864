import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { EXPLORE_CARD_DATE_FORMAT, JOB_TYPE_KEYS } from 'common/constants'
import { getSymbolByCurrency } from 'common/utils/getSymbolByCurrency'
import envConfig from 'config'
import { selectUserVacanciesById } from 'features/Home/selectors'
import { getMyUid, selectMyVacancies } from 'features/MyProfile/selectors'
import { selectOnboardingTranslations, selectVacanciesTranslations } from 'features/Translations/selectors'

export const useVacancyDetails = (uid: string, vacancyId: string) => {
  const myUid = useSelector(getMyUid)
  const myVacancies = useSelector(selectMyVacancies)
  const otherUserVacancies = useSelector(selectUserVacanciesById(uid))
  const onboardingTranslations = useSelector(selectOnboardingTranslations)
  const vacanciesTranslations = useSelector(selectVacanciesTranslations)
  const isMe = uid === myUid
  const vacanciesOtherUser = otherUserVacancies?.length ? otherUserVacancies : []
  const vacancy = (isMe ? myVacancies : vacanciesOtherUser).find(({ id }) => id === vacancyId)
  const audience = vacancy?.audience ? vacanciesTranslations[vacancy?.audience] : null
  const dateCardDetails = `${vacancy?.updated ? vacanciesTranslations.edited : ''} ${dayjs(vacancy?.ts).format('MMM DD')}`
  const dateDetails = `${vacancy?.updated ? vacanciesTranslations.openToEdited : vacanciesTranslations.openToPublished}
    ${dayjs(vacancy?.ts).format(EXPLORE_CARD_DATE_FORMAT)}`

  const workingDetails = useMemo(() => {
    let formattedSalary = null
    if (vacancy?.salaryMin && vacancy?.salaryMax) {
      const period = onboardingTranslations[vacancy?.payPeriod ?? '']
      const currencySymbol = getSymbolByCurrency(vacancy?.currency ?? '')
      formattedSalary = `${vacancy?.salaryMin} - ${vacancy?.salaryMax}${currencySymbol}/${period}`
    }

    return [
      { key: 'vacancyType', value: vacancy?.type ? vacanciesTranslations[vacancy?.type] : null },
      { key: JOB_TYPE_KEYS.ON_SITE, value: vacancy?.onsite ? vacanciesTranslations[JOB_TYPE_KEYS.ON_SITE] : null },
      { key: JOB_TYPE_KEYS.HYBRID, value: vacancy?.hybrid ? vacanciesTranslations[JOB_TYPE_KEYS.HYBRID] : null },
      { key: JOB_TYPE_KEYS.REMOTE, value: vacancy?.remote ? vacanciesTranslations[JOB_TYPE_KEYS.REMOTE] : null },
      { key: 'formatSalaryDetails', value: formattedSalary }
    ].filter((detail) => detail.value)
  }, [vacancy])

  return {
    name: vacancy?.jobName,
    status: vacancy?.status,
    dateDetails,
    dateCardDetails,
    details: {
      currency: vacancy?.currency,
      salaryMax: vacancy?.salaryMax || null,
      salaryMin: vacancy?.salaryMin || null,
      description: vacancy?.description || null,
      payPeriod: vacancy?.payPeriod,
      remote: vacancy?.remote || false,
      onsite: vacancy?.onsite || false,
      hybrid: vacancy?.hybrid || false,
      specialities: vacancy?.specialities || []
    },
    locations: vacancy?.locations || [],
    tags: vacancy?.tags || [],
    type: vacancy?.type,
    vacancyId: vacancy?.id,
    audience: envConfig.features?.jobAudienceEnabled ? audience : null,
    employer: vacancy?.employer,
    url: vacancy?.url || '',
    workingDetails
  }
}
