import { FC, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { GhostButton } from 'common/components/Button_V2/GhostButton'
import { SecondaryButton } from 'common/components/Button_V2/SecondaryButton'
import { ImageWithTrustMark } from 'common/components/ImageWithTrustMark_V2'
import { ChevronUpIcon } from 'common/icons_V2/ChevronUpIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { colorTheMatch } from 'common/utils/colorTheMatch'
import { approveJoinRequest, denyJoinRequest } from 'features/MyProfile/actions'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

export const GROUP_ACTIONS_KEYS = {
  OPEN_GROUP: 'open group',
  GROUP_REQUESTS: 'group requests'
}

interface IGroupItem {
  name: string,
  photo?: string,
  photoUrl?: string,
  uid: string,
  groupRef: string,
  searchString?: string,
  buttonType: string,
  onClick: () => void,
}

export const GroupItem: FC<IGroupItem> = memo(({
  name, photo, photoUrl, uid, groupRef, searchString, buttonType, onClick
}) => {
  const dispatch = useDispatch()
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)

  const renderActions = () => {
    if (buttonType === GROUP_ACTIONS_KEYS.GROUP_REQUESTS) {
      return (
        <div className={styles.requestButtons}>
          <SecondaryButton
            title={myNetworkTranslations.groupMemberButtonDeny}
            onClick={(e) => {
              e.stopPropagation()
              dispatch(denyJoinRequest(groupRef, uid))
            }}
          />
          <GhostButton
            title={myNetworkTranslations.groupMemberButtonApprove}
            onClick={(e) => {
              e.stopPropagation()
              dispatch(approveJoinRequest(groupRef, uid))
            }}
          />
        </div>
      )
    }
    if (buttonType === GROUP_ACTIONS_KEYS.OPEN_GROUP) {
      return (
        <ChevronUpIcon rotate={90} />
      )
    }

    return null
  }

  return (
    <div
      className={cn(
        styles.groupItem,
        buttonType === GROUP_ACTIONS_KEYS.OPEN_GROUP && styles.groupItemHover
      )}
      onClick={onClick}
    >
      <ImageWithTrustMark
        uid={uid}
        photoURL={photoUrl || ''}
        photo={photo || ''}
        alt={name}
        width={56}
        isGroupImage
      />
      <div className={styles.groupContent}>
        <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p" className={styles.name}>
          {colorTheMatch(name, searchString || '')}
        </Typography>
      </div>
      <div className={styles.buttonContainer}>
        {renderActions()}
      </div>
    </div>
  )
})
