export const UntrustUserIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7007_103504)">
      <path d="M8 7.5C8 8.56087 8.42143 9.57828 9.17157 10.3284C9.92172 11.0786 10.9391 11.5 12 11.5C13.0609 11.5 14.0783 11.0786 14.8284 10.3284C15.5786 9.57828 16 8.56087 16 7.5C16 6.43913 15.5786 5.42172 14.8284 4.67157C14.0783 3.92143 13.0609 3.5 12 3.5C10.9391 3.5 9.92172 3.92143 9.17157 4.67157C8.42143 5.42172 8 6.43913 8 7.5Z" stroke="#374957" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 21.5V19.5C6 18.4391 6.42143 17.4217 7.17157 16.6716C7.92172 15.9214 8.93913 15.5 10 15.5H10.5" stroke="#374957" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.6757 15.4333C15.8626 15.274 16.1374 15.274 16.3243 15.4333L16.807 15.8446C17.0472 16.0494 17.3459 16.1731 17.6606 16.1982L18.2927 16.2486C18.5375 16.2682 18.7318 16.4625 18.7514 16.7073L18.8018 17.3394C18.8269 17.6541 18.9506 17.9528 19.1554 18.193L19.5667 18.6757C19.726 18.8626 19.726 19.1374 19.5667 19.3243L19.1554 19.807C18.9506 20.0472 18.8269 20.3459 18.8018 20.6606L18.7514 21.2927C18.7318 21.5375 18.5375 21.7318 18.2927 21.7514L17.6606 21.8018C17.3459 21.8269 17.0472 21.9506 16.807 22.1554L16.3243 22.5667C16.1374 22.726 15.8626 22.726 15.6757 22.5667L15.193 22.1554C14.9528 21.9506 14.6541 21.8269 14.3394 21.8018L13.7073 21.7514C13.4625 21.7318 13.2682 21.5375 13.2486 21.2927L13.1982 20.6606C13.1731 20.3459 13.0494 20.0472 12.8446 19.807L12.4333 19.3243C12.274 19.1374 12.274 18.8626 12.4333 18.6757L12.8446 18.193C13.0494 17.9528 13.1731 17.6541 13.1982 17.3394L13.2486 16.7073C13.2682 16.4625 13.4625 16.2682 13.7073 16.2486L14.3394 16.1982C14.6541 16.1731 14.9528 16.0494 15.193 15.8446L15.6757 15.4333Z" stroke="#374957" strokeLinejoin="round" />
      <path d="M12 22.5L20 16.5" stroke="#2C3E50" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_7007_103504">
        <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)
