import React from 'react'
import { JOB_TYPE_KEYS, SYMBOLS } from 'common/constants'
import { AboutMe } from 'common/icons/socialLinks/AboutMe'
import { Angellist } from 'common/icons/socialLinks/Angellist'
import { Behance } from 'common/icons/socialLinks/Behance'
import { Dribble } from 'common/icons/socialLinks/Dribble'
import { Facebook } from 'common/icons/socialLinks/Facebook'
import { Fiver } from 'common/icons/socialLinks/Fiver'
import { Freelancer } from 'common/icons/socialLinks/Freelancer'
import { Github } from 'common/icons/socialLinks/Github'
import { Instagram } from 'common/icons/socialLinks/Instagram'
import { Jobcase } from 'common/icons/socialLinks/Jobcase'
import { LinkedIn } from 'common/icons/socialLinks/LinkedIn'
import { Medium } from 'common/icons/socialLinks/Medium'
import { Pinterest } from 'common/icons/socialLinks/Pinterest'
import { Quora } from 'common/icons/socialLinks/Quora'
import { Reddit } from 'common/icons/socialLinks/Reddit'
import { Snapchat } from 'common/icons/socialLinks/Snapchat'
import { StackOverflow } from 'common/icons/socialLinks/StackOverflow'
import { Tiktok } from 'common/icons/socialLinks/Tiktok'
import { Tumblr } from 'common/icons/socialLinks/Tumblr'
import { Twitch } from 'common/icons/socialLinks/Twitch'
import { Twitter } from 'common/icons/socialLinks/Twitter'
import { Upwork } from 'common/icons/socialLinks/Upwork'
import { Vimeo } from 'common/icons/socialLinks/Vimeo'
import { Xing } from 'common/icons/socialLinks/Xing'
import { Youtube } from 'common/icons/socialLinks/Youtube'
import { Countries, CountryNames } from 'features/Locations/types'
import type { VacancyType } from 'features/MyProfile/types'

export const formatLocation = (
  location: string,
  allLocations: {
    countries: Countries,
    countryNames: CountryNames,
    regions: { [key: string]: { [key: string]: string } }
  },
  showCountryCode = false
) => {
  if (!location) return ''
  const [country, region, city] = location.split(SYMBOLS.BE_LOCATION_SEPARATOR)
  const countryResult = showCountryCode ? allLocations?.countryNames?.[country] : allLocations?.countries?.[country]
  const result = [city, getRegionShort(country, region, allLocations.regions), countryResult || country]
  return result.filter(Boolean).join(SYMBOLS.FE_LOCATION_SEPARATOR)
}

export const normalizeLocation = (location: string): string => {
  if (!location) return ''
  return location.replace(/(,\s*)+$/, '').trim()
}

export const joinLocations = (country: string, region: string, city: string, separator: string) => {
  if (!region && !city) return `${country}`
  if (!city) return `${country}${separator}${region}`
  return `${country}${separator}${region}${separator}${city}`
}

export const findLocationRecord = (
  item: string[],
  cities: string[][],
  regions: { [key: string]: { [key: string]: string } }
) => {
  const compare = (str1: string, str2: string) => (str1 || '').toLowerCase() === (str2 ?? '').toLowerCase()
  const found = cities.find(
    ([countryCode, region, city]) => {
      return compare(countryCode, item[0])
        && (compare(getRegionShort(countryCode, region, regions), item[1]) || compare(region, item[1]))
        && compare(city, item[2])
    }
  ) ?? []
  return found
}

export const getRegionShort = (
  country:string, region: string, regions: { [key: string]: { [key: string]: string } }
) => {
  return regions[country]?.[region]?.[0] || region
}

export const getWorkingDetails = (jobDetails: VacancyType, vacanciesTranslations: { [key: string]: string }) => {
  if (!jobDetails) return {}
  const { onsite, hybrid, remote } = jobDetails
  const jobTypeDetails = [
    { key: JOB_TYPE_KEYS.ON_SITE, value: onsite ? vacanciesTranslations[JOB_TYPE_KEYS.ON_SITE] : null },
    { key: JOB_TYPE_KEYS.HYBRID, value: hybrid ? vacanciesTranslations[JOB_TYPE_KEYS.HYBRID] : null },
    { key: JOB_TYPE_KEYS.REMOTE, value: remote ? vacanciesTranslations[JOB_TYPE_KEYS.REMOTE] : null }
  ].filter((detail) => detail.value)

  return { jobTypeDetails }
}

export const formatJobTypeDetails = (jobDetails: VacancyType, vacanciesTranslations: { [key: string]: string }) => {
  const { jobTypeDetails } = getWorkingDetails(jobDetails, vacanciesTranslations)
  if (!jobTypeDetails) return ''
  if (jobTypeDetails.length > 2) {
    const last = jobTypeDetails[jobTypeDetails.length - 1]
    return `${jobTypeDetails.slice(0, 2).map((detail) => detail.value).join(', ')} or ${last.value}`
  }
  return jobTypeDetails.map((detail) => detail.value).join(' or ')
}

export const LINK_ICONS_MAPPING: { [key: string]: React.FC } = {
  'facebook.com': Facebook,
  'fb.com': Facebook,
  'instagram.com': Instagram,
  'instagr.am': Instagram,
  'tiktok.com': Tiktok,
  'tiktokv.com': Tiktok,
  'youtube.com': Youtube,
  'reddit.com': Reddit,
  'redd.it': Reddit,
  'pinterest.com': Pinterest,
  'pi.it': Pinterest,
  'snapchat.com': Snapchat,
  'sc.com': Snapchat,
  'linkedin.com': LinkedIn,
  'linkd.in': LinkedIn,
  'x.com': Twitter,
  't.co': Twitter,
  'quora.com': Quora,
  'qr.ae': Quora,
  'twitch.tv': Twitch,
  'vimeo.com': Vimeo,
  'tumblr.com': Tumblr,
  'medium.com': Medium,
  'venture.angellist': Angellist,
  'github.com': Github,
  'behance.net': Behance,
  'dribbble.com': Dribble,
  'about.me': AboutMe,
  'xing.com': Xing,
  'jobcase.com': Jobcase,
  'freelancer.com': Freelancer,
  'stackoverflow.com': StackOverflow,
  'upwork.com': Upwork,
  'fiverr.com': Fiver
}

export const getLinkDetails = (url?: string): React.FC | null => {
  if (!url) return null
  // .co should come after .com
  const pattern = /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+)\.(in|ae|com|am|net|org|tv|me|co|it|angellist)/
  const match = url.match(pattern)
  if (!match) return null
  const domain = `${match[1]}.${match[2]}`.toLowerCase()
  return LINK_ICONS_MAPPING[domain]
}
