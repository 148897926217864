// TODO: Implement a fallback to a different storage mechanism
// to gain access to localStorage if cookies are disabled

interface ILocalStorageService {
  setItem: (key: string, value: string, excludeFromClear?: boolean) => void
  getItem: (key: string) => string | null
  removeItem: (key: string) => void
  clear: () => void
}

const EXCEPTION_KEYS_FOR_CLEAR = new Set<string>()

const LocalStorageService: ILocalStorageService = {
  setItem: (key: string, value: string, excludeFromClear = false): void => {
    try {
      localStorage.setItem(key, JSON.stringify(value))
      if (excludeFromClear) {
        EXCEPTION_KEYS_FOR_CLEAR.add(key)
      }
    } catch (error) {
      // Error saving to localStorage
    }
  },

  getItem: (key: string): string | null => {
    try {
      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : null
    } catch (error) {
      return null
    }
  },

  removeItem: (key: string): void => {
    try {
      localStorage.removeItem(key)
      EXCEPTION_KEYS_FOR_CLEAR.delete(key)
    } catch (error) {
      // Error removing from localStorage
    }
  },

  clear: (): void => {
    try {
      for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i)
        if (key && !EXCEPTION_KEYS_FOR_CLEAR.has(key)) {
          localStorage.removeItem(key)
        }
      }
    } catch (error) {
      // Error clearing localStorage
    }
  }
}

export default LocalStorageService
