import React from 'react'

export const colorTheMatch = (name: any, searchString: any) => {
  try {
    const safeCheckedName = name || ''
    if (!searchString) return safeCheckedName
    const searchSize = searchString.length
    const search = searchString.replace(/[\\+.*?^${}()|[\]]/g, '\\$&')
    const indexes = [...safeCheckedName.matchAll(new RegExp(search, 'gi'))].map(({ index }) => index)
    const splitted = safeCheckedName.split(new RegExp(search, 'gi'))

    // Fills the splitted array with the string splitted by
    const boldedMatch = splitted.reduce((acc: string[], text: string, index: number) => {
      const matchIndex = indexes[index]
      const boldedString = React.createElement('strong', { key: `match-${matchIndex}` }, safeCheckedName.slice(matchIndex, matchIndex + searchSize))
      if (matchIndex === 0) return [...acc, boldedString, text]
      if (index === splitted.length - 1 && matchIndex !== splitted.length - 1 - searchSize) return [...acc, text]
      return [...acc, text, boldedString]
    }, [] as string[])

    return boldedMatch
  } catch (error) {
    return name
  }
}

/*
TODO: Verify

import React, { ReactElement, ReactNode } from 'react'

interface ValidElement extends ReactElement {
  props: {
    children: ReactNode[]
  }
}

export const colorTheMatch = (name: ReactNode, searchString: string): ReactNode => {
  try {
    const safeCheckedName = name || ''
    if (!searchString) return safeCheckedName

    if (
      React.isValidElement(safeCheckedName) &&
      safeCheckedName.props &&
      Array.isArray((safeCheckedName as ValidElement).props.children)
    ) {
      const children = (safeCheckedName as ValidElement).props.children.map((child: ReactNode) => {
        if (typeof child === 'string') {
          return highlightText(child, searchString)
        }
        return child
      })

      return React.cloneElement(safeCheckedName, { children } as any)
    }

    if (typeof safeCheckedName === 'string') {
      return highlightText(safeCheckedName, searchString)
    }

    return safeCheckedName
  } catch (error) {
    return name
  }
}

const highlightText = (text: string, searchString: string): ReactNode => {
  if (!searchString) return text

  const searchSize = searchString.length
  const search = searchString.replace(/[\\+.*?^${}()|[\]]/g, '\\$&')
  const regex = new RegExp(search, 'gi')
  const matches: number[] = []
  let match: RegExpExecArray | null

  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(text)) !== null) {
    matches.push(match.index)
  }

  if (matches.length === 0) return text

  const result: (string | ReactElement)[] = []
  let lastIndex = 0

  matches.forEach((matchIndex, i) => {
    const endIndex = matchIndex + searchSize
    result.push(text.substring(lastIndex, matchIndex))
    result.push(React.createElement(
      'strong',
      { key: i, style: { color: '#222629' } },
      text.substring(matchIndex, endIndex)
    ))
    lastIndex = endIndex
  })

  result.push(text.substring(lastIndex))
  return React.createElement(React.Fragment, null, result)
}
 */
