import React from 'react'

export const ENIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11860_57713)">
      <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#F0F0F0" />
      <path d="M11.6087 12.0002H21C21 11.1879 20.8917 10.4009 20.6899 9.65234H11.6087V12.0002Z" fill="#D80027" />
      <path d="M11.6087 7.30438H19.679C19.1281 6.40537 18.4237 5.61073 17.6024 4.95654H11.6087V7.30438Z" fill="#D80027" />
      <path d="M12 21C14.1181 21 16.065 20.2679 17.6024 19.0435H6.39764C7.93503 20.2679 9.88188 21 12 21Z" fill="#D80027" />
      <path d="M4.32098 16.6955H19.6791C20.1214 15.9738 20.4644 15.1849 20.69 14.3477H3.31006C3.53566 15.1849 3.87868 15.9738 4.32098 16.6955Z" fill="#D80027" />
      <path d="M7.16897 4.40548H7.98913L7.22624 4.95971L7.51765 5.85652L6.75479 5.30228L5.99194 5.85652L6.24366 5.08178C5.57196 5.64129 4.98323 6.29681 4.49808 7.02722H4.76087L4.27526 7.38001C4.1996 7.50622 4.12704 7.63444 4.0575 7.76455L4.28939 8.47826L3.85676 8.16393C3.74921 8.39177 3.65085 8.62475 3.56243 8.86259L3.81791 9.64896H4.76087L3.99798 10.2032L4.28939 11.1L3.52654 10.5458L3.06957 10.8778C3.02384 11.2454 3 11.6199 3 12H12C12 7.02947 12 6.44348 12 3C10.2221 3 8.56471 3.51574 7.16897 4.40548ZM7.51765 11.1L6.75479 10.5458L5.99194 11.1L6.28335 10.2032L5.52046 9.64896H6.46342L6.75479 8.75216L7.04617 9.64896H7.98913L7.22624 10.2032L7.51765 11.1ZM7.22624 7.58146L7.51765 8.47826L6.75479 7.92402L5.99194 8.47826L6.28335 7.58146L5.52046 7.02722H6.46342L6.75479 6.13042L7.04617 7.02722H7.98913L7.22624 7.58146ZM10.7459 11.1L9.98305 10.5458L9.22019 11.1L9.51161 10.2032L8.74871 9.64896H9.69168L9.98305 8.75216L10.2744 9.64896H11.2174L10.4545 10.2032L10.7459 11.1ZM10.4545 7.58146L10.7459 8.47826L9.98305 7.92402L9.22019 8.47826L9.51161 7.58146L8.74871 7.02722H9.69168L9.98305 6.13042L10.2744 7.02722H11.2174L10.4545 7.58146ZM10.4545 4.95971L10.7459 5.85652L9.98305 5.30228L9.22019 5.85652L9.51161 4.95971L8.74871 4.40548H9.69168L9.98305 3.50868L10.2744 4.40548H11.2174L10.4545 4.95971Z" fill="#0052B4" />
    </g>
    <defs>
      <clipPath id="clip0_11860_57713">
        <rect width="18" height="18" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
)
