import { ProfileType } from 'features/MyProfile/types'

export enum Privacy {
  Public = 'public',
  Private = 'private'
}

export type UrlHistory = {
  url: string
  ts: string
  uid: string
}

export type GroupType = {
  id: string
  name: string
  owner: string
  admins: string[]
  members: string[]
  membersData: ProfileType[]
  requests?: string[]
  requestsData?: ProfileType[]
  url: string
  photoURL?: string
  photo?: string
  created: string
  privacy: Privacy
  url_history: UrlHistory[]
}

export interface GroupLocationState {
  filter: number
}
