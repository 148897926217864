import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { NetworkFilters } from 'features/Contacts/Network/MyNetwork'
import { ContactList } from 'features/Contacts/Network/MyNetwork/components/ContactList'
import { SectionDataType } from 'features/Contacts/types'
import { selectNetworkFilters } from 'features/FilterItems/selectors'
import { selectContactUids, selectTrustedUids } from 'features/MyProfile/selectors'

interface IDefaultState {
  searchString: string
  isSearchStringValid: boolean
  sectionsData: SectionDataType,
  onShowGlobalSearchData: () => void
}

export const DefaultState: FC<IDefaultState> = ({
  searchString,
  isSearchStringValid,
  sectionsData,
  onShowGlobalSearchData
}) => {
  const networkFilters = useSelector(selectNetworkFilters)
  const trustedUids = useSelector(selectTrustedUids)
  const allContactsUids = useSelector(selectContactUids)

  const renderContactList = (selectedFilter: NetworkFilters) => {
    if (!sectionsData[selectedFilter]) {
      return null
    }

    const {
      items,
      title,
      emptyStateTitle,
      buttonType,
      showLoadingInAllPage,
      getContactInfo,
      renderEmptySearchState,
      showTitle,
      isShowButtonVisible,
      isGroup
    } = sectionsData[selectedFilter]
    return (
      <ContactList
        title={title}
        emptyStateTitle={emptyStateTitle}
        items={items}
        searchString={searchString}
        trustedUids={trustedUids}
        allContactsUids={allContactsUids}
        buttonType={buttonType}
        getContactInfo={getContactInfo}
        showLoadingInAllPage={showLoadingInAllPage}
        renderEmptySearchState={renderEmptySearchState}
        isShowButtonVisible={isShowButtonVisible}
        onShowGlobalSearchData={onShowGlobalSearchData}
        showTitle={showTitle}
        isGroup={isGroup}
      />
    )
  }

  const renderActiveTabContent = () => {
    switch (networkFilters[0]) {
      case NetworkFilters.FIRST_LEVEL:
      case NetworkFilters.SECOND_LEVEL:
      case NetworkFilters.GROUP:
      case NetworkFilters.ALL_CONTACTS:
      case NetworkFilters.GLOBAL_SEARCH:
        return renderContactList(networkFilters[0])
      case NetworkFilters.ALL:
      default:
        return (
          <>
            {renderContactList(NetworkFilters.FIRST_LEVEL)}
            {renderContactList(NetworkFilters.SECOND_LEVEL)}
            {renderContactList(NetworkFilters.GROUP)}
            {renderContactList(NetworkFilters.ALL_CONTACTS)}
            {isSearchStringValid && renderContactList(NetworkFilters.GLOBAL_SEARCH)}
          </>
        )
    }
  }

  return (
    <>
      {renderActiveTabContent()}
    </>
  )
}
