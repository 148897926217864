import { useSelector } from 'react-redux'
import { Typography, TypographyVariants } from 'common/typography'
import { Modal } from 'features/Modal_V2'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IConfirmRemoveContactModal {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  contactName: string
}

export const ConfirmRemoveContactModal = ({
  isOpen, onClose, onSubmit, contactName
}: IConfirmRemoveContactModal) => {
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      width={424}
      title={myNetworkTranslations.removeContactModalTitle}
      secondaryButtonText={myNetworkTranslations.removeContactSecondaryButtonText}
      secondaryButtonOnClick={onClose}
      primaryButtonText={myNetworkTranslations.removeContactPrimaryButtonText}
      primaryButtonOnClick={onSubmit}
    >
      <Typography variant={TypographyVariants.Desktop_UI_L_Regular} tag="p" className={styles.modalDescription}>
        {myNetworkTranslations.removeContactModalDescription} <strong>{` ${contactName}`}?</strong>
      </Typography>
    </Modal>
  )
}
