import { FC, useState } from 'react'
import cn from 'classnames'
import { CopyIcon } from 'common/icons_V2/CopyIcon'
import { Typography, TypographyVariants } from 'common/typography'
import copyToClipboard from 'copy-to-clipboard'
import styles from './styles.module.sass'

interface ICopyButton {
  shareLink: string
  title: string
  copiedTitle: string
  copyButtonContainer?: string
  linkContainer?: string
  showLink?: boolean
}

export const CopyButton: FC<ICopyButton> = ({
  shareLink,
  title,
  copiedTitle,
  copyButtonContainer,
  linkContainer,
  showLink = false
}) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = () => {
    copyToClipboard(shareLink)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 1000)
  }

  const CopyText = (
    <Typography
      variant={TypographyVariants.Desktop_UI_M_Medium}
      tag="span"
      className={styles.copiedText}
    >
      {isCopied ? copiedTitle : title}
    </Typography>
  )

  return (
    <div className={cn(styles.shareLinkContainer, copyButtonContainer)}>
      {showLink ? (
        <div className={cn(styles.linkContainer, linkContainer)}>
          <input
            type="text"
            value={shareLink}
            readOnly
            className={styles.shareLinkInput}
          />
          <button className={styles.copyButtonInline} onClick={handleCopy}>
            {CopyText}
          </button>
        </div>
      ) : (
        <button className={styles.copyButton} onClick={handleCopy}>
          {isCopied ? (
            CopyText
          ) : (
            <div className={styles.copyText}>
              <CopyIcon />
              {CopyText}
            </div>
          )}
        </button>
      )}
    </div>
  )
}
