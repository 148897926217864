import type { ActionTypes } from 'common/types'
import { initLanguage } from 'features/Auth/actions'

const initialState = {
  pagesScrollYPosition: {} as { [pageRoute: string]: number },
  initialized: false,
  isFullScreen: false,
  isSWActive: false,
  isInitialLogin: false,
  language: initLanguage()
}

export const AppReducer = (state = initialState, action: ActionTypes): typeof initialState => {
  switch (action.type) {
    case 'APP__SET_PAGE_SCROLL_POSITION':
      return {
        ...state,
        pagesScrollYPosition: {
          ...state.pagesScrollYPosition,
          [action.payload.pageRoute]: action.payload.scrollY
        }
      }
    case 'APP__SET_INITIALIZED':
      return {
        ...state,
        initialized: action.initialized
      }
    case 'APP__SET_IS_FULL_SCREEN':
      return {
        ...state,
        isFullScreen: action.isFullScreen
      }
    case 'APP__SET_SW_ACTIVATED':
      return {
        ...state,
        isSWActive: action.isSWActive
      }
    case 'APP__SET_INITIAL_LOGIN':
      return {
        ...state,
        isInitialLogin: action.isInitialLogin
      }
    case 'APP__SET_LANGUAGE':
      return {
        ...state,
        language: action.language
      }
    default: return state
  }
}
