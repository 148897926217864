import React, {
  FC,
  ReactNode,
  useEffect,
  useRef
} from 'react'
import { FieldErrors, FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { GhostButton } from 'common/components/Button_V2/GhostButton'
import { TransparentButton } from 'common/components/Button_V2/TransparentButton'
import { Typography, TypographyVariants } from 'common/typography'
import { FormInput } from 'features/FormInput_V2'
import { TagsInputField } from 'features/FormInput_V2/TagsInputField'
import { selectEditProfileTranslations, selectMyProfileTranslations } from 'features/Translations/selectors'
import { ProfileSections, useProfileSections } from 'providers/ProfileSectionsProvider'
import styles from './styles.module.sass'

interface IFormValues {
  [key: string]: string
}

interface IGenericFormField {
  name: string,
  title?: string,
  helperText?: string,
  type: 'text' | 'textarea' | 'tags',
  placeholder?: string,
  defaultValue: any,
  showMaxCharCount?: boolean,
  maxCharCount?: number
}

interface IGenericForm {
  fields: IGenericFormField[],
  error: string | null | any,
  onSubmit: (data: any) => void,
  formHelperText?: string,
  formTitle?: string,
  viewOnly?: boolean,
  children?: ReactNode,
  onCancel: () => void,
  isOpen?: boolean,
  setIsOpenAboutElement?: React.Dispatch<React.SetStateAction<boolean>>,
  CustomComponent?: FC<any>,
  sectionName: ProfileSections
}

const Form: FC<Pick<IGenericForm, 'viewOnly' | 'setIsOpenAboutElement' | 'sectionName' | 'isOpen' | 'fields' | 'CustomComponent' | 'onCancel' | 'onSubmit' | 'error'>> = ({
  isOpen,
  fields,
  CustomComponent,
  onCancel,
  onSubmit,
  error,
  sectionName,
  setIsOpenAboutElement,
  viewOnly
}) => {
  const {
    activeSection,
    setActiveSection,
    getIsSectionActive
  } = useProfileSections()
  const editFormFooter = useRef<HTMLDivElement>(null)
  const formInputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)
  const isEditing = getIsSectionActive?.(sectionName)
  const myProfileTabsTranslations = useSelector(selectMyProfileTranslations)
  const defaultValues = fields.reduce((acc, field) => {
    acc[field.name] = field.defaultValue ?? ''
    return acc
  }, {} as IFormValues)

  useEffect(() => {
    if (activeSection === sectionName && editFormFooter.current) {
      editFormFooter.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }, [activeSection])

  useEffect(() => {
    if (isEditing && formInputRef.current) {
      formInputRef.current.focus()
    }
    if (isEditing) {
      setIsOpenAboutElement?.(isEditing)
    }
  }, [activeSection])

  const methods = useForm<IFormValues>({
    mode: 'onBlur',
    shouldFocusError: false,
    defaultValues
  })

  const {
    handleSubmit, clearErrors, trigger, formState: { errors, isDirty, isSubmitting }, reset
  } = methods

  const handleOnDiscard = () => {
    setActiveSection?.(null)
    setIsOpenAboutElement?.(false)
    reset(defaultValues)
  }

  useEffect(() => {
    if (!isOpen && isDirty) {
      handleOnDiscard()
    }
  }, [isOpen])

  const errorMessage = error || fields.reduce((acc: any, field) => {
    return acc || (errors as FieldErrors<IFormValues>)[field.name]?.message
  }, null)

  const handleOnCancel = () => {
    if (isDirty) {
      onCancel()
    } else {
      setActiveSection?.(null)
    }
  }

  const internalOnSubmit = async (data: IFormValues) => {
    if (await trigger()) {
      onSubmit({ ...data })
      setActiveSection?.(null)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(internalOnSubmit)} className={cn(styles.aboutElementForm)}>
        {CustomComponent ? (
          <CustomComponent
            register={methods.register}
            errors={errorMessage}
            clearErrors={clearErrors}
            trigger={trigger}
            isSubmitted={methods.formState.isSubmitted}
            isOnEditing={isEditing}
            isEditable={!viewOnly}
            {...defaultValues}
          />
        ) : (
          fields.map((field, index) => (
            <div key={index} className={styles.formFieldContainer}>
              {field.type !== 'tags' && ( // TODO: Use CustomComponent
                <FormInput
                  ref={formInputRef}
                  title={field.title}
                  fieldName={field.name}
                  error={errorMessage}
                  maxCharCount={field.maxCharCount}
                  placeholder={field.placeholder}
                  defaultValue={field.defaultValue}
                  clearErrors={clearErrors}
                  register={methods.register}
                  trigger={trigger}
                  isTextarea={field.type === 'textarea'}
                  isOnEditing
                  showMaxCharCount={field.showMaxCharCount}
                  autoFocus={isEditing}
                />
              )}
              {field.type === 'tags' && (
                <TagsInputField
                  title={field.title}
                  fieldName={field.name}
                  error={errorMessage}
                  placeholder={field.placeholder}
                  maxCharCount={field.maxCharCount}
                  defaultValue={field.defaultValue}
                  showMaxCharCount={!!errorMessage}
                  autoFocus={isEditing}
                />
              )}
            </div>
          ))
        )}
        <div className={styles.fieldButtons} ref={editFormFooter}>
          <GhostButton
            title={myProfileTabsTranslations.profileEditingCancelButton}
            onClick={handleOnCancel}
            disabled={isSubmitting}
          />
          <TransparentButton
            type="submit"
            title={myProfileTabsTranslations.profileEditingSaveButton}
            isLoading={isSubmitting}
            disabled={isSubmitting || !!errorMessage}
          />
        </div>
      </form>
    </FormProvider>
  )
}

export const GenericForm: FC<IGenericForm> = ({
  fields,
  error,
  onSubmit,
  formHelperText,
  formTitle,
  viewOnly = false,
  children,
  onCancel,
  isOpen,
  setIsOpenAboutElement,
  CustomComponent,
  sectionName
}) => {
  const {
    activeSection,
    setActiveSection,
    getIsSectionActive,
    getIsSectionInActive
  } = useProfileSections()
  const isEditing = getIsSectionActive?.(sectionName)
  const editProfileTranslations = useSelector(selectEditProfileTranslations)

  if (viewOnly && !children) return <></>

  return (
    <div className={cn(
      !children && styles.emptyData,
      styles.aboutElement,
      getIsSectionInActive?.(sectionName) && styles.inActive
    )}
    >
      {isEditing ? (
        <Form
          isOpen={isOpen}
          fields={fields}
          CustomComponent={CustomComponent}
          onCancel={onCancel}
          onSubmit={onSubmit}
          error={error}
          sectionName={sectionName}
          setIsOpenAboutElement={setIsOpenAboutElement}
          viewOnly={viewOnly}
        />
      ) : (
        <>
          {children
            ? (
              <div className={styles.aboutElementWithData}>
                <Typography variant={TypographyVariants.Desktop_UI_L_Semibold} tag="div" className={styles.fieldTitle}>
                  {formTitle ?? fields[0]?.title}
                </Typography>
                {!viewOnly && (
                  <GhostButton
                    title={editProfileTranslations.editBtn}
                    onClick={() => setActiveSection?.(sectionName)}
                    disabled={!!activeSection}
                    className={styles.editButton}
                  />
                )}
              </div>
            )
            : (
              <Typography variant={TypographyVariants.Body_1_Medium} tag="div" onClick={() => setActiveSection?.(sectionName)}>
                {formHelperText}
              </Typography>
            )}
          {children}
          {fields.length > 1 && fields.some((a) => a.defaultValue === undefined) && !viewOnly &&
            fields.map((field, index) => (
              field.defaultValue === undefined && (
                <div key={index} className={styles.emptyData}>
                  <Typography
                    variant={TypographyVariants.Body_1_Medium}
                    tag="div"
                    onClick={() => setActiveSection?.(sectionName)}
                  >
                    {field.helperText}
                  </Typography>
                </div>
              )
            ))}
        </>
      )}
    </div>
  )
}
