import React, { FC, useRef } from 'react'
import cn from 'classnames'
import { useOutside } from 'common/hooks/useOutside'
import { CheckedIcon } from 'common/icons/CheckedIcon'
import { PreloaderIcon } from 'common/icons/PreloaderIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { DropDownItemType } from 'features/NavBar/types'
import styles from './styles.module.sass'

interface IDropdownMenu {
  dropDownList: DropDownItemType[]
  onCloseList: () => void,
  dropdownMenuStyles?: string,
  dropdownItemStyles?: string
}

export const DropdownMenu: FC<IDropdownMenu> = ({
  dropDownList, dropdownMenuStyles, dropdownItemStyles, onCloseList
}) => {
  const dropDownListEventRef = useRef<HTMLDivElement>(null)
  useOutside(dropDownListEventRef, onCloseList)

  return (
    <div
      className={cn(styles.dropdownMenu, dropdownMenuStyles)}
      ref={dropDownListEventRef}
    >
      {dropDownList.map((item, index) => (
        <div
          key={index}
          className={cn(styles.dropdownItem, dropdownItemStyles)}
          onClick={() => {
            item.onClick?.()
            if (!item.remainOpen) {
              onCloseList()
            }
          }}
        >
          {item.isLoading && (<PreloaderIcon stroke="#ccd5e4" />)}
          {item.icon}
          {item.title && (
            <Typography
              variant={TypographyVariants.Desktop_UI_M_Regular}
              tag="p"
              style={{ color: item.textColor }}
            >
              {item.title}
            </Typography>
          )}
          {!!item.isActive && <CheckedIcon stroke="#306FD1" />}
          {item.customItem}
        </div>
      ))}
    </div>
  )
}
