import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { EXPLORE_CARD_DATE_FORMAT, JOB_TYPE_KEYS } from 'common/constants'
import { useSpecialties } from 'common/hooks/useSpecialities'
import { getSymbolByCurrency } from 'common/utils/getSymbolByCurrency'
import envConfig from 'config'
import { selectOnboardingTranslations, selectVacanciesTranslations } from 'features/Translations/selectors'

export const useSpecialityDetails = (uid: string, specialityId: string) => {
  const { activeSpecialities: specialities } = useSpecialties(uid)
  const onboardingTranslations = useSelector(selectOnboardingTranslations)
  const vacanciesTranslations = useSelector(selectVacanciesTranslations)
  const speciality = specialities?.find(({ id }: { id: string }) => id === specialityId)
  const audience = speciality?.audience ? vacanciesTranslations[speciality?.audience] : null
  const dateCardDetails = `${speciality?.updated ? vacanciesTranslations.edited : ''} ${dayjs(speciality?.ts).format('MMM DD')}`
  const dateDetails = `${speciality?.updated ? vacanciesTranslations.openToEdited : vacanciesTranslations.openToPublished}
    ${dayjs(speciality?.ts).format(EXPLORE_CARD_DATE_FORMAT)}`

  const workingDetails = useMemo(() => {
    let formattedSalary = null
    if (speciality?.salaryMin && speciality?.salaryMax) {
      const period = onboardingTranslations[speciality?.payPeriod ?? '']
      const currencySymbol = getSymbolByCurrency(speciality?.currency ?? '')
      formattedSalary = `${speciality?.salaryMin} - ${speciality?.salaryMax}${currencySymbol}/${period}`
    }

    return [
      { key: 'vacancyType', value: speciality?.type ? vacanciesTranslations[speciality?.type] : null },
      { key: JOB_TYPE_KEYS.ON_SITE, value: speciality?.onsite ? vacanciesTranslations[JOB_TYPE_KEYS.ON_SITE] : null },
      { key: JOB_TYPE_KEYS.HYBRID, value: speciality?.hybrid ? vacanciesTranslations[JOB_TYPE_KEYS.HYBRID] : null },
      { key: JOB_TYPE_KEYS.REMOTE, value: speciality?.remote ? vacanciesTranslations[JOB_TYPE_KEYS.REMOTE] : null },
      { key: 'formatSalaryDetails', value: formattedSalary }
    ].filter((detail) => detail.value)
  }, [speciality])

  return {
    name: speciality?.jobName,
    status: speciality?.status,
    dateDetails,
    dateCardDetails,
    details: {
      currency: speciality?.currency,
      salaryMax: speciality?.salaryMax || null,
      salaryMin: speciality?.salaryMin || null,
      description: speciality?.description || null,
      payPeriod: speciality?.payPeriod,
      remote: speciality?.remote || false,
      onsite: speciality?.onsite || false,
      hybrid: speciality?.hybrid || false,
      specialities: speciality?.specialities || []
    },
    locations: speciality?.locations || [],
    tags: speciality?.tags || [],
    type: speciality?.type,
    vacancyId: speciality?.id,
    audience: envConfig.features?.jobAudienceEnabled ? audience : null,
    url: speciality?.url || '',
    workingDetails
  }
}
