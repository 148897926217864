export const PeopleIcon = ({ size = 39 }) => (
  <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3385_7454)">
      <mask id="mask0_3385_7454" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
        <path d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3385_7454)">
        <path d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="#306FD1" fillOpacity="0.1" />
      </g>
      <circle cx="4.35702" cy="17.5816" r="3.58163" fill="#306FD1" fillOpacity="0.3" />
      <ellipse cx="5" cy="30.7143" rx="9" ry="8.35714" fill="#306FD1" fillOpacity="0.3" />
      <circle cx="28.4942" cy="22.078" r="8.07797" fill="#306FD1" fillOpacity="0.3" />
      <circle cx="28.4412" cy="50.2979" r="18.4412" fill="#306FD1" fillOpacity="0.3" />
      <circle cx="50.839" cy="17.5816" r="3.58163" fill="#306FD1" fillOpacity="0.3" />
      <ellipse cx="51.8823" cy="30.5205" rx="9" ry="8.35714" fill="#306FD1" fillOpacity="0.3" />
    </g>
    <defs>
      <clipPath id="clip0_3385_7454">
        <rect width="56" height="56" rx="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
