import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import { SecondaryButton } from 'common/components/Button_V2/SecondaryButton'
import { DotsVerticalIcon } from 'common/icons_V2/DotsVerticalIcon'
import { Share } from 'features/MyProfile/components/ProfilePopup/Share'
import { UnTrust } from 'features/MyProfile/components/ProfilePopup/Untrust'
import { selectMyNetworkContacts } from 'features/MyProfile/selectors'
import { ProfileType } from 'features/MyProfile/types'
import { selectOtherProfileTranslations } from 'features/Translations/selectors'
import { Introduce } from './Introduce'
import styles from './styles.module.sass'

interface IProfilePopup {
  uid: string,
  profile?: ProfileType,
}

export const ProfilePopup: FC<IProfilePopup> = ({ uid, profile }) => {
  const otherProfileTranslations = useSelector(selectOtherProfileTranslations)
  const [isOpen, setIsOpen] = useState(false)
  const closePopup = () => setIsOpen(false)
  const contacts = useSelector(selectMyNetworkContacts)
  const isContact = contacts.some((contact) => contact.uid === uid)

  return (
    <div>
      <Popup
        trigger={(
          <div className={styles.popupTrigger}>
            <SecondaryButton
              icon={<DotsVerticalIcon />}
              onClick={() => setIsOpen(true)}
            />
          </div>
        )}
        open={isOpen}
        position={['right top', 'left top']}
      >
        <div className={styles.menuButtons}>
          {!isContact && !!profile && (
            <Introduce closePopup={closePopup} profile={profile} />
          )}
          {!!profile && (
            <Share uid={uid} title={otherProfileTranslations.shareOtherProfile} closePopup={closePopup} />
          )}
          {isContact && (
            <UnTrust uid={uid} closePopup={closePopup} />
          )}
        </div>
      </Popup>
    </div>
  )
}
