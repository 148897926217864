import React from 'react'
import { Image } from 'common/components/Image_V2'
import { Typography, TypographyVariants } from 'common/typography'
import styles from './styles.module.sass'

interface IGroupParticipantInfo {
  photoURL?: string
  photo?: string
  groupName: string
}

export const GroupParticipantInfo: React.FC<IGroupParticipantInfo> = ({
  photoURL,
  photo,
  groupName
}) => {
  return (
    <div className={styles.participantsContainer}>
      <Image photoURL={photoURL || ''} photo={photo || ''} alt={groupName} width={96} isRounded isGroupImage />
      <Typography variant={TypographyVariants.Desktop_UI_L_Medium} className={styles.headerTitle} tag="div">
        {groupName}
      </Typography>
    </div>
  )
}
