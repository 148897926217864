import { doc, onSnapshot } from 'firebase/firestore'
import { LANGUAGE_TYPE } from 'common/enums'
import translationsFallbackEN from 'common/translationsFallbackEN.json'
import translationsFallbackHY from 'common/translationsFallbackHY.json'
import { DispatchType, TranslationsType } from 'features/Translations/types'
import { firestoreDb } from 'store/store'

export enum ACTION_TYPES {
  SET_DATA = 'TRANSLATIONS__SET_DATA',
  SET_IS_LOADING = 'TRANSLATIONS__SET_IS_LOADING'
}

export const actions = {
  set: (data: TranslationsType) => ({ type: ACTION_TYPES.SET_DATA, data }) as const,
  setIsLoading: (isLoading: boolean) => ({ type: ACTION_TYPES.SET_IS_LOADING, isLoading }) as const
}

export const listenLokaliseChanges = (dispatch: DispatchType, language: LANGUAGE_TYPE) => {
  const docRef = doc(firestoreDb, 'lokalise', 'web')
  return onSnapshot(docRef, async (doc) => {
    const lokaliseFileName = `${language}/web.strings.json`
    const response = doc.data()
    if (response?.files?.length) {
      try {
        const webStringsUrl = response.files.find((url: string) => {
          const decodedUrl = decodeURIComponent(url)
          return decodedUrl.includes(lokaliseFileName)
        })
        if (webStringsUrl) {
          const translations = await fetch(webStringsUrl, { method: 'GET' })
          const translationsData = await translations.json()
          dispatch(actions.set(translationsData))
        } else {
          setTranslationsFallback(dispatch, language)
        }
      } catch {
        setTranslationsFallback(dispatch, language)
      } finally {
        dispatch(actions.setIsLoading(false))
      }
    } else {
      setTranslationsFallback(dispatch, language)
    }
  }, () => setTranslationsFallback(dispatch, language))
}

const setTranslationsFallback = (dispatch: DispatchType, language :string) => {
  if (language === LANGUAGE_TYPE.HY) {
    dispatch(actions.set(translationsFallbackHY))
  } else {
    dispatch(actions.set(translationsFallbackEN))
  }
}
