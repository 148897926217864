import React from 'react'

export const HYIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11860_57722)">
      <path d="M21 11.9998C21 10.8989 20.802 9.8443 20.4402 8.86935L12 8.47803L3.55979 8.86932C3.19804 9.8443 3 10.8989 3 11.9998C3 13.1007 3.19804 14.1552 3.55979 15.1302L12 15.5215L20.4402 15.1302C20.802 14.1552 21 13.1007 21 11.9998Z" fill="#0052B4" />
      <path d="M12 21C15.8697 21 19.1686 18.5576 20.4402 15.1304H3.55981C4.83145 18.5576 8.13034 21 12 21Z" fill="#FF9811" />
      <path d="M3.55981 8.86958H20.4403C19.1686 5.44237 15.8697 3 12 3C8.13034 3 4.83145 5.44237 3.55981 8.86958Z" fill="#D80027" />
    </g>
    <defs>
      <clipPath id="clip0_11860_57722">
        <rect width="18" height="18" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
)
